import "babel-polyfill"
//import "isomorphic-fetch"
import React from "react"
import Helmet from "react-helmet"

import parse from "url-parse"
import Layout from "../components/layout"

// import Amplify, { API } from "aws-amplify"
import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
// import Auth from '@aws-amplify/auth';
// import Cache from '@aws-amplify/cache';

import { amplifyconfig } from "../utils/config"

Amplify.configure(amplifyconfig)

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorUrl: "",
    }
  }

  componentDidMount() {
    const url = parse(window.location.href, true)

    const formData = {
      formtype: "engage",
    }

    if (url.query && url.query.p) {
      formData.path = url.query.p
    }
    if (url.query && url.query.s) {
      formData.sub = url.query.s
    }
    if (url.query && url.query.tc) {
      formData.typecategory = url.query.tc
    }
    if (url.query && url.query.m) {
      formData.mkt = url.query.m
    }
    if (url.query && url.query.c) {
      formData.campaign = url.query.c
    }
    if (url.query && url.query.t) {
      formData.templateid = url.query.t
    }

    // POST ENGAGE DATA
    let apiName = "Storylavaweb"
    let path = "interact"
    let options = {
      body: formData,
    }

    if (url.query && url.query.p) {
      let redirectUrl = url.origin + url.query.p
      if (url.query.s && url.query.c && url.query.t) {
        redirectUrl += `?t=${url.query.t}&c=${url.query.c}&s=${url.query.s}`
      }

      console.log("redirectUrl")
      console.log(redirectUrl)

      // BEGIN API POST
      API.post(apiName, path, options)
        .then(response => {
          console.log("submitForm response")
          console.log(response)
          if (typeof window !== "undefined") {
            window.location.replace(redirectUrl)
          }
        })
        .catch(error => {
          this.setState({
            errorUrl: redirectUrl,
          })
          console.log(error)
        })

      // END API POST
    }
  }

  render() {
    return (
      <div>
        {this.state.errorUrl ? (
          <Layout>
            <div class="container container-page container-page-demo-3">
              <div class="row d-flex flex-column justify-content-center align-items-center text-center">
                <div class="col-lg-8">
                  <p>
                    We had difficulty loading the page. Click on the link below
                    to access it:
                  </p>
                  <p>
                    <a href={this.state.errorUrl} />
                  </p>
                </div>
              </div>
            </div>
          </Layout>
        ) : null}
      </div>
    )
  }
}
